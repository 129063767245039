import React from "react"
import { Link } from "gatsby"

import AdamsLogo from '../assets/sam-adams.svg';
import AdamsCompetitors from '../assets/Sam-adams-competitors.png';
import AdamsIssue1 from '../assets/Sam-adams-issue1.png';
import AdamsPage7 from '../assets/SamAdams-Page_07.jpg';
import AdamsPage15 from '../assets/SamAdams-Page_15.jpg';
import AdamsPage17 from '../assets/SamAdams-Page_17.jpg';
import AdamsPage28 from '../assets/SamAdams-Page_28.jpg';
import ArrowRight from '../assets/arrow-right.svg';

import Layout from "../components/layout"
import '../components/home.scss';
import '../components/projects.scss';

export default function Home() {
  return (
    <Layout>
      <section className="section-no-margin">
        <div className="row">
          <div className="align-center section-line">
            <img className="col-6 margin-btm-lg WI-header" src={AdamsLogo} alt="Sam Adams logo" />
            <div className="align-left">
              <h1 className="header--medium margin-btm-xxs">Sam Adams - Market Research</h1>
              <div className="flex flex-break-sm">
                <div className="col-7 divider-coral col-100 mobile-margin-btm-xs">
                  <p>In 2016, Sam Adams came to ISL wanting to market more to millennials. I reviewed their past marketing campaigns, conducted market research, did competitive analysis, and research on the type of marketing millennials reacted best to and lead a SWOT analysis exercise.</p>

                  <p>The end product was a document that helped our team understand Sam Adams's marketing and brand, which allowed the ISL team to produce a campaign that helped Sam Adams achieve their goals. The document also provided recommendations to Sam Adams so that they could continue building on the marketing effort ISL put together for them.</p>
                </div>
                <div className="flex flex-col col-5 col-100">
                  <div className="margin-btm-xxs">
                    <label className="header-label">Role:</label>
                    <span className="text-sm">UX Director</span>
                  </div>
                  <div className="list-sm list-reponsibilities">
                    <label className="header-label">Responsibilities:</label>
                    <ul className="flex-grid-list">
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Market Research</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>Competitive Analysis</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>SWOT Analysis</span>
                      </li>
                      <li className="list-item text-sm flex-row flex flex-align">
                        <span>User Research</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-4 row-paddingflex flex-center row-padding-md flex-break-sm col-100">
              <h3 className="header--small">How Sam Adams Was Marketing</h3>
              <p>After reviewing Sam Adams's past marketing campaigns, I realized why they were having problems reaching millennials. They were simply showing images of their beer, and having their owner and staff simply say how great it was. They didn’t seem to have a real strategy as to how they were marketing or who they were marketing to.</p>
            </div>
            <div className="column column2 col-8 col-100">
              <div className="project">
                <img src={AdamsIssue1} alt="One issue outlined and a recommendation" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-8 row-padding-md row-padding-md-mob-none col-100 mobile-margin-btm-xs">
              <div className="project section-no-margin">
                <img src={AdamsCompetitors} alt="information about some of Sam Adam's competitors" />
              </div>
            </div>
            <div className="column column2 col-4 flex flex-center col-100">
              <h3 className="header--small">Competitors</h3>
              <p>Their competitors, on the other hand, had a much stronger brand voice and were focused on very specific groups of people. For example, Blue Moon is focused on liberals who are willing to go against convention and Heineken focuses on people who are attracted to more luxury products.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="row-projects flex-break-sm">
            <div className="column column1 col-6 row-padding-md col-100 row-padding-md-mob-none">
              <div className="margin-btm-md">
                <h3 className="header--small">End Product</h3>
                <p>In the end, I produced a doc outlining issue areas, characteristics of the people they were currently reaching, characteristics of millennials and what they looked for in brands,  recommendations on how to speak more to millennials, and potential new audiences allowing the ISL team to build a success campaign for Sam Adams.</p>
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={AdamsPage7} alt="Another solution to an problem outlined" />
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={AdamsPage17} alt="A recommendation that Sam Adams market more of their varietals" />
              </div>
            </div>
            <div className="column column2 col-6 col-100">
              <div className="project">
                <img className="withShadow project-with-border" src={AdamsPage28} alt="Information about one of their target markets" />
              </div>
              <div className="project">
                <img className="withShadow project-with-border" src={AdamsPage15} alt="Information about millennials" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-fullwidth">
        <div className="footer-projects footer-violin">
          <div className="wrapper-project-footer flex-center column">
            <div className="section-internal flex flex-row space-between">
              <div>
                <span className="next-previous">Next Project</span>
                <Link to="/violin-cat/">
                  <h3 className="header--medium">Cat with Violin</h3>
                </Link>
                <span className="description">Illustration</span>
              </div>
              <Link to="/violin-cat/">
                <img className="next-arrow" src={ArrowRight} alt="arrow" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
